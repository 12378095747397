/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
import default_org_img from '../../assets/img/logos/no_gl_seal_light.png'; // eslint-disable-next-line
import loader_image from '../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import { getCurrencyAmount, getCurrencySymbol } from '../api/copiriBankingApi';
import ColumnChart from '../amcharts/banking/ColumnChart';
function TreeItem({ item, funcs, nChild }) {
	// eslint-disable-next-line
	const { getStripeLink, checkLoader, inValidateLink, pageLanguageContentData, showModal, showHideChildRows } = funcs;
	return (
		<>
			<div className={nChild ? 'accordion-main childClassOrg' : 'accordion-main parentClassOrg'} style={{ backgroundColor: item.color,display:nChild?'none':'block' }} id={`banking_child_row_${item?.id}`}>
				<div className='accordion-item'>
					<div className='accordion-header d-flex align-items-center'>
						{item.children && 
						<><span className='accordion-icon' onClick={() => showHideChildRows(item.id, item,'show')} id={`show_hide_right_${item?.id}`}>
							{' '}
							<i className='fas fa-angle-right' ></i>{' '}
						</span>

						<span className='accordion-icon' style={{display:'none'}} onClick={() => showHideChildRows(item.id, item,'hide')} id={`show_hide_down_${item?.id}`}>
							{' '}
							<i className='fas fa-angle-down' ></i>{' '}
						</span>
						</> }
						<span className='org_img'>
							{item.seal ? (
								<img
									className='rounded-circle avatar-xl'
									src={item.seal}
									width='40'
									height='40'
									alt={pageLanguageContentData['HME011'] ? pageLanguageContentData['HME011'] : ''}
								/>
							) : (
								<img
									className='rounded-circle avatar-xl'
									src={default_org_img}
									width='40'
									height='40'
									alt={pageLanguageContentData['HME011'] ? pageLanguageContentData['HME011'] : ''}
								/>
							)}
						</span>
						<p className='org_name mb-0'>{item?.org_name}</p>
					</div>

					<div className='accordion-bodyaccordion-body'>
						{item.dashboard ? (
							<div className='card-list'>
								<div className='row'>
									<div className='col-lg-3 col-md-6 col-12 px-1'>
										<div className='card' style={{ cursor: 'default',minHeight: '85px' }}>
											<span className='dashboard_tile'>
												{pageLanguageContentData['HME008'] ? pageLanguageContentData['HME008'] : ''}
											</span>
											<p className='amount-number m-0'>{getCurrencyAmount(item?.balance, item?.payments?.currency)}</p>
										</div>
									</div>
									<div className='col-lg-3 col-md-6 col-12 px-1' onClick={() => showModal(item)} style={{ cursor: 'pointer' }}>
										<div className='card' style={{ minHeight: '85px' }}>
											<div onClick={() => showModal(item)} style={{ cursor: 'pointer',width:'100%' }}>
												<span className='dashboard_tile'>
													{pageLanguageContentData['HME009'] ? pageLanguageContentData['HME009'] : ''}
												</span>
												<p className='amount-number m-0'>
													{getCurrencyAmount(item?.payments?.value, item?.payments?.currency)}
												</p>
											</div>
											<div>
												<div													
													role='progressbar'
													aria-valuenow='20'
													aria-valuemin='20'
													aria-valuemax='100'
												>
													<ColumnChart data={item?.payments?.data} chartId={`chart-User-Breakdown_${item?.id}`} height={'50px'} />
												</div>
											</div>
										</div>
									</div>
									<div className='col-lg-3 col-md-6 col-12 px-1'>
										<div className='card' style={{ minHeight: '85px'}}>
											<div onClick={() => showModal(item)} style={{ cursor: 'pointer',width:'100%' }}>
												<span className='dashboard_tile'>
													{pageLanguageContentData['HME010'] ? pageLanguageContentData['HME010'] : ''}
												</span>
												<p className='amount-number m-0'>{item?.payments?.total}</p>
											</div>
											<div className='progress red-tooltip'  id={`progress_bar_tooltip_${item?.id}`} data-toggle='tooltip'
																data-placement='top'
																title={` ${item?.payments?.total} ${pageLanguageContentData['HME015'] ? pageLanguageContentData['HME015'] : ''}`}>
												<div 
													className={"progress-bar " + (item?.payments?.percent < 100  ? 'in_progress_bar' : 'success_progress_bar')}
													role='progressbar'
													aria-valuenow='20'
													aria-valuemin='20'
													aria-valuemax='100'
													style={{ width: `${item?.payments?.percent}%` }}
												></div>
											</div>
										</div>
									</div>
									{item.seller_id && (
										<div className='col-lg-3 col-md-6 col-12 px-1 manageAcc'>
											<div className={`card bank_card ${item?.account?.badge}`} onClick={() => getStripeLink(item.seller_id)} style={{ cursor: 'pointer',minHeight: '85px' }}>
												<div className='bank-details'>
													{checkLoader && checkLoader[item.seller_id] ? (
														<span>
															{pageLanguageContentData['HME003'] ? pageLanguageContentData['HME003'] : ''}
															<img
																className='rounded-circle avatar-xl'
																src={loader_image}
																alt='img'
																width='100px'
																id={''}
																style={{ marginLeft: '5px' }}
															/>
														</span>
													) : (
														<>
															{inValidateLink ? (
																<span>
																	<p className='amount-number'>{item?.account?.status}</p>
																	<p className='bankname'>{item?.account?.institution}</p>
																	<p className='dashboard_tile'>
																		{pageLanguageContentData['HME016'] ? pageLanguageContentData['HME016'] : ''}
																	</p>
																</span>
															) : (
																<span style={{ cursor: 'pointer' }}>
																	<p className='amount-number'>{item?.account?.status}</p>
																	<p className='bankname'>{item?.account?.institution}</p>
																	<p className='dashboard_tile'>
																		{pageLanguageContentData['HME016'] ? pageLanguageContentData['HME016'] : ''} 
																		<i className='fas fa-square-up-right ml-1'></i>
																	</p>
																</span>
															)}
														</>
													)}
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
			{item.children && <TreeList item={item} tree={item.children} funcs={funcs} key={item.id} nChild={true} />}
		</>
	);
}
function TreeList({ item, tree, funcs, nChild = false }) {
	return (
		<>
			{tree.map((child, index) => (
				<TreeItem item={child} funcs={funcs} key={index} nChild={nChild} />
			))}
		</>
	);
}
const BankingDashboardTree = ({
	data,
	pageLanguageContent,
	paymentData,
	getStripeLink,
	checkLoader,
	inValidateLink,
	pageLanguageContentData,
	showModal,
	showHideChildRows
}) => {
	// eslint-disable-next-line
	const [tree, setTree] = useState(paymentData);
	const funcs = {
		getStripeLink,
		checkLoader,
		inValidateLink,
		pageLanguageContentData,
		showModal,
		showHideChildRows
	};
	return <TreeList tree={tree} funcs={funcs} />;
};
export default BankingDashboardTree;

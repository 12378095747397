import React, { useState, useEffect } from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import './style.css'; 
import { copiriBankingPortalRequest } from '../api/copiriBankingApi'; 
import config from '../../config';
import { checkValidation } from '../api/api';
import { toast } from 'react-toastify';
const ActivateAccount = props => {
	const {
		enableActivateAccountClick,
		pageLanguageContent,
		hasEmail,
		searchData,
		userEmail,
		callbackToDuesPayment,
		callbackEnableDisableButton,
		selectedPaymentType,
		enableDisableSubmitButton
	} = props;
	const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
	const [checkTermsOfService, setCheckTermsOfService] = useState(false); // eslint-disable-next-line
	const [consentText, setConsentText] = useState(false);
	const [showHideTermsPolicy, setShowHideTermsPolicy] = useState(false);
	const [policyErrorText, setPolicyErrorText] = useState('');
	const [termsErrorText, setTermsErrorText] = useState('');
	const [confirmConsentErrorText, setConfirmConsentErrorText] = useState('');
	 const [showPassword, setShowPassword] = useState(false);
	/* set and get variable for input data */
	const [inputField, setInputField] = useState({
		email: '',
		password: '',
		confirm_password: ''
	});
	/* set and get varaible for input errors */
	const [validation, setValidation] = useState({
		error_email: '',
		error_password: '',
		error_confirm_password: ''
	});

	useEffect(() => {
		if (enableActivateAccountClick) {
			updateUserEmailActivateAccount();
		}
		// eslint-disable-next-line
	}, [props]);

	/* function to set the input email field data */
	const inputsHandler = name => e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
		var p_value = document.getElementById('account_password').value;
		var cp_cvalue = document.getElementById('account_confirm_password').value;
		if (p_value || cp_cvalue) {
			setShowHideTermsPolicy(true);
			enableDisableSubmitButton('enable');
		} else {
			setShowHideTermsPolicy(false);
			enableDisableSubmitButton('disable');
		}
	};

	/* submit input data and call PATCH update user's API */
	// eslint-disable-next-line
	function updateUserEmailActivateAccount() {
		let regExpression = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$');
		let errors = { ...validation };
		var validationError = false;
		setPolicyErrorText('');
		setTermsErrorText('');
		setConfirmConsentErrorText('');
		// this will execute if user has no email on file
		if (!hasEmail) {
			if (!inputField.email.trim()) {
				errors.error_email = pageLanguageContent['PMT042'] ? pageLanguageContent['PMT042'] : '';
				validationError = true;
			} else if (!checkValidation(inputField.email, 'email')) {
				errors.error_email = pageLanguageContent['PMT043'] ? pageLanguageContent['PMT043'] : '';
				validationError = true;
			} else {
				errors.error_email = '';
			}
		}
		if (inputField.password && inputField.password !== '') {
			if (inputField.password.length < 10) {
				errors.error_password = pageLanguageContent['PMT045'] ? pageLanguageContent['PMT045'] : '';
				validationError = true;
			}

			if (inputField.password.length >= 10) {
				if (!regExpression.test(inputField.password)) {
					errors.error_password = pageLanguageContent['PMT046'] ? pageLanguageContent['PMT046'] : '';
					validationError = true;
				} else {
					errors.error_password = '';
				}
				if (regExpression.test(inputField.password)) {
					if (inputField.password.toLowerCase() !== inputField.confirm_password.toLowerCase()) {
						errors.error_password = pageLanguageContent['PMT047'] ? pageLanguageContent['PMT047'] : '';
						validationError = true;
					} else if (checkCharacterReccuring(inputField.password)) {
						errors.error_password = pageLanguageContent['PMT055'] ? pageLanguageContent['PMT055'] : '';
						validationError = true;
					} else if (!checkPrivacyPolicy) {
						setPolicyErrorText(pageLanguageContent['PMT056'] ? pageLanguageContent['PMT056'] : '');
						validationError = true;
					} else if (!checkTermsOfService) {
						setTermsErrorText(pageLanguageContent['PMT057'] ? pageLanguageContent['PMT057'] : '');
						validationError = true;
					} else if (!consentText) {
						setConfirmConsentErrorText(pageLanguageContent['PMT058'] ? pageLanguageContent['PMT058'] : '');
						validationError = true;
					} else {
						errors.error_password = '';
					}
				}
			}
		} else {
			errors.error_password = '';
		}
		setValidation(errors);
		if (!validationError) {
			var proceedToApi = true;
			var sendFormData = {};
			var userId = searchData?.user_id;
			if (inputField.email && inputField.email !== '') {
				sendFormData['email'] = inputField.email;
			}
			var url = '/users/' + userId;
			if (inputField.password && inputField.password !== '') {
				if (inputField.password.indexOf(' ') >= 0) {
					if (window.confirm(pageLanguageContent['PMT054'] ? pageLanguageContent['PMT054'] : '')) {
						proceedToApi = true;
					} else {
						proceedToApi = false;
					}
				} else {
				}
			}
			if (proceedToApi) {
				if (selectedPaymentType && selectedPaymentType !=='') {
					proceedToCallAPIEndpoints(url, sendFormData, searchData?.key, userId);
				} else {
					if (window.confirm(pageLanguageContent['PMT064']?pageLanguageContent['PMT064']:'')) {
						proceedToCallAPIEndpoints(url, sendFormData, searchData?.key, userId);
					}	
				}
				
			} else {
				callbackEnableDisableButton(inputField.email);
			}
		} else {
			callbackEnableDisableButton(inputField.email);
		}
	}
	/* function to call API endpoints step by step*/
	const proceedToCallAPIEndpoints = async (url, sendFormData, token, userId) => {
		let email_done,
			activate_done,
			updateEmailDone,
			updateActiveAccountDone = false;
		if (!hasEmail) {
			// call update user's email endpoint
			await copiriBankingPortalRequest(url, config.app_version, 'patch', sendFormData, token)
				.then(response => {
					if (response && response.data && response.data.status === 'SUCCESS') {
						// if API success, set email done to true
						email_done = true;
						//toast.success(response.data.status);
					} else {
						toast.error(pageLanguageContent['PMT010']);
						callbackEnableDisableButton(inputField.email);
					}
				})
				.catch(error => {
					callbackEnableDisableButton(inputField.email);
				});
		} else email_done = true;
		var updatedEmail = searchData.email;
		if (!hasEmail) updatedEmail = inputField.email;
		if (email_done && inputField.password !== '') {
			var actvateAccountFormData = {};
			actvateAccountFormData['consent'] = false;
			actvateAccountFormData['privacy'] = false;
			actvateAccountFormData['terms'] = false;
			actvateAccountFormData['password'] = inputField.password;
			//actvateAccountFormData['password'] = 1234567890;
			if (consentText) actvateAccountFormData['consent'] = true;
			if (checkPrivacyPolicy) actvateAccountFormData['privacy'] = true;
			if (checkTermsOfService) actvateAccountFormData['terms'] = true;
			await copiriBankingPortalRequest(
				'/users/' + userId + '/activate',
				config.app_version,
				'post',
				actvateAccountFormData,
				token
			)
				.then(response => {
					if (response.status && response.status === 200) {
						activate_done = true;
						//toast.success('Success');
					} else {
						callbackEnableDisableButton(updatedEmail);
					}
				})
				.catch(error => {
					callbackEnableDisableButton(updatedEmail);
				});
			//activate_done = true;
		} else activate_done = true;
		if (email_done && activate_done) {
			// call back function to call payments due API.
			//updateEmailDone,updateActiveAccountDone
			if (email_done) updateEmailDone = true;
			if (inputField.password !== '' && activate_done) updateActiveAccountDone = true;
			callbackToDuesPayment(updatedEmail, updateEmailDone, updateActiveAccountDone);
		}
	};

	/* function to check if character repaet repeat three times */
	function checkCharacterReccuring(string) {
		for (var i = 0; i < string.length; i++)
			if (string[i] === string[i + 1] && string[i + 1] === string[i + 2]) return true;
		return false;
	}

	const togglePasswordVisibility = () => {
		if (inputField.password || inputField.confirm_password) {
	    	setShowPassword(!showPassword);
	    }
	  };

	return (
		<>
			<Row className='mb-3 align-items-center'>
				{/* Section for udpate user's email starts */}
				<Col xs='6'>
					<h6 className='mb-0'>{pageLanguageContent['PMT034'] ? pageLanguageContent['PMT034'] : ''}</h6>
				</Col>
				{!hasEmail ? (
					<>
						<Col xs='6' className='text-right'>
							<input
								type='text'
								onChange={inputsHandler('email')}
								value={inputField.email}
								name='email'
								className='form-control activate_account email_field'
								style={{ fontSize: '0.8333rem' }}
							/>
							
						</Col>
						<Col md='12' className='text-right mb-1 mt-1'>
							{validation.error_email && (
								<p className='formValidationError' style={{ marginTop: '-3px', right: '15px' }}>
									{validation.error_email}
								</p>
							)}
						</Col>
						<Col xs='12' className='mt-2'>
							<div className='important-notes' style={{ marginTop: '3px' }}>
								{pageLanguageContent['PMT037'] ? pageLanguageContent['PMT037'] : ''}
							</div>
						</Col>
					</>
				) : (
					<>
						<Col xs='6' className='text-right'>
							<span style={{ fontSize: '0.8333rem', fontWeight: '600' }} className="userName">{userEmail}</span>
						</Col>
						<Col xs='12'>
							<div className='important-notes'>
								{pageLanguageContent['PMT035'] ? pageLanguageContent['PMT035'] : ''}{' '}
								<a href='mailto:support@copiri.com'>
									{pageLanguageContent['PMT036'] ? pageLanguageContent['PMT036'] : ''}
								</a>
								.
							</div>
						</Col>
					</>
				)}
				{/* Section for udpate user's email ends */}
			</Row>
			{searchData?.policy?.confirm && searchData?.policy?.consent && (
				<>
					<Row className='mb-3 align-items-center'>
						<Col xs='12'>
							<h6 className='mb-1'>{pageLanguageContent['PMT048'] ? pageLanguageContent['PMT048'] : ''}</h6>
						</Col>
						<Col xs='12'>
							<div className='important-notes'>
								{pageLanguageContent['PMT049'] ? pageLanguageContent['PMT049'] : ''}
							</div>
						</Col>
					</Row>

					<div className='cetnerCreateAccount'>
						<Row className='mb-1 align-items-center'>
							<Col xs='6'>
								<p className='mb-0'>{pageLanguageContent['PMT050'] ? pageLanguageContent['PMT050'] : ''}</p>
							</Col>
							<Col xs='6' className='text-right pl-1 d-flex align-items-center' style={{border:'1px solid #d8e2ef', borderRadius:'0.25rem'}}>
								<input
									type={showPassword ? 'text' : 'password'}
									onChange={inputsHandler('password')}
									value={inputField.password}
									name='password'
									className='form-control activate_account password_field'
									id='account_password'
								/>
								<i className="fas fa-eye showHideEyeBall1" onClick={togglePasswordVisibility}></i>
							</Col>
						</Row>
						<Row className='mb-3 align-items-center'>
							<Col xs='6'>
								<p className='mb-0'>{pageLanguageContent['PMT051'] ? pageLanguageContent['PMT051'] : ''}</p>
							</Col>
							<Col xs='6' className='text-right pl-1 d-flex align-items-center' style={{border:'1px solid #d8e2ef', borderRadius:'0.25rem'}}>
								<input
									type={showPassword ? 'text' : 'password'}
									onChange={inputsHandler('confirm_password')}
									value={inputField.confirm_password}
									name='confirm_password'
									className='form-control activate_account password_field'
									id='account_confirm_password'
								/>
								<i className="fas fa-eye showHideEyeBall1" onClick={togglePasswordVisibility}></i>

							</Col>
							<Col xs='12' className='text-right mb-1'>
								{validation.error_password && (
									<p
										className='formValidationError'
										style={{ right: '15px', marginTop: '2px', fontSize: '0.7rem', lineHeight: '1.2', zIndex: '99999' }}
									>
										{validation.error_password}
									</p>
								)}
							</Col>
						</Row>
						{showHideTermsPolicy && (
							<>
								<Row className='mb-1 align-items-center terms_privacy_policy_' style={{ marginTop: '1.8rem' }}>
									<Col xs='8'>
										{searchData?.policy?.privacy ? (
											<p className='mb-0'>
												<span onClick={() => setCheckPrivacyPolicy(!checkPrivacyPolicy)}>
													{pageLanguageContent ? pageLanguageContent['PMT059'].split('_LINK_TEXT_')[0] : ''}
												</span>
												<a
													href={searchData.policy.privacy}
													target='_blank'
													rel='noopener noreferrer'
													style={{ cursor: 'pointer' }}
												>
													<span>{pageLanguageContent ? pageLanguageContent['PMT060'] : ''}</span>
												</a>
											</p>
										) : (
											<p className='mb-0'>{pageLanguageContent['PMT052'] ? pageLanguageContent['PMT052'] : ''}</p>
										)}
									</Col>
									<Col xs='4' className='text-right'>
										<CustomInput
											id='show-privacy-policy'
											checked={checkPrivacyPolicy}
											onChange={() => setCheckPrivacyPolicy(!checkPrivacyPolicy)}
											type='checkbox'
										/>
									</Col>
									<Col xs='12'>
										<p className='formValidationError' style={{ fontSize: '0.7rem', marginTop: '-6px' }}>
											{policyErrorText}
										</p>
									</Col>
								</Row>
								<Row className='mb-2 align-items-center terms_privacy_policy_'>
									<Col xs='8'>
										{searchData?.policy?.terms ? (
											<p className='mb-0'>
												<span onClick={() => setCheckTermsOfService(!checkTermsOfService)}>
													{pageLanguageContent ? pageLanguageContent['PMT059'].split('_LINK_TEXT_')[0] : ''}
												</span>
												<a
													href={searchData.policy.terms}
													target='_blank'
													rel='noopener noreferrer'
													style={{ cursor: 'pointer' }}
												>
													<span style={{ cursor: 'pointer', color: '#337ab7' }}>
														{pageLanguageContent ? pageLanguageContent['PMT061'] : ''}
													</span>
												</a>
											</p>
										) : (
											<p className='mb-0'>{pageLanguageContent['PMT053'] ? pageLanguageContent['PMT053'] : ''}</p>
										)}
									</Col>
									<Col xs='4' className='text-right'>
										<CustomInput
											id='show-terms-service'
											checked={checkTermsOfService}
											onChange={() => setCheckTermsOfService(!checkTermsOfService)}
											type='checkbox'
										/>
									</Col>
									<Col xs='12'>
										<p className='formValidationError' style={{ fontSize: '0.7rem', marginTop: '-4px' }}>
											{termsErrorText}
										</p>
									</Col>
								</Row>
								<Row className='mb-1 align-items-center show-consent-confirmation-text terms_privacy_policy_'>
									<Col xs='12'>
										<div className='important-notes' style={{ marginTop: '4px' }}>
											<p style={{ textAlign: 'justify' }} className='legal mb-0'>
												{searchData?.policy?.consent}
											</p>
											<div className='terms-checkbox d-flex mt-2 align-items-center'>
												<CustomInput
													id='show-consent-confirmation-text'
													onChange={() => setConsentText(!consentText)}
													checked={consentText}
													type='checkbox'
												/>
												<p onClick={() => setConsentText(!consentText)} className='legal mb-0'>
													{' '}
													{searchData?.policy?.confirm}{' '}
												</p>
											</div>
										</div>
										<p className='formValidationError' style={{ marginTop: '0px', fontSize: '0.7rem' }}>
											{confirmConsentErrorText}
										</p>
									</Col>
								</Row>
							</>
						)}
					</div>
				</>
			)}
		</>
	);
};
export default ActivateAccount;
